import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfirmModalPage } from '../modals/confirm-modal/confirm-modal.page';
import { GlobalService } from './global.service';
import { InAppReview } from '@capacitor-community/in-app-review';
import { Capacitor } from '@capacitor/core';

@Injectable({ 
  providedIn: 'root'
})
export class TrainingService {

  private subscriptions = new Subscription();

  public trainingSessionsData: BehaviorSubject<any> = new BehaviorSubject<[]>(null); 
  public logout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public iceCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  public iceCheckMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null); 

  newLocationDetails = {'coordinates':{'lat':0,
                                      'lon':0},
                        'url':"",
                        'words':"",
                        'groupID':0
                    };

 public setGroupLocation:  BehaviorSubject<Array<{}>> =  new BehaviorSubject<Array<{}>>([this.newLocationDetails]); 

  public groupData: BehaviorSubject<Array<'any'>> = new BehaviorSubject<[]>(null); 

  public trainingCount: BehaviorSubject<any> = new BehaviorSubject<any>(0);

  constructor(private globalService : GlobalService,
    private router: Router,
    private modalController: ModalController,
    ) { }

     


   listData: any = []
    covid
    covidSecure
    trainingLength
    
    modelData
    covidConfirm
    outConfirm
  
  async getTrainingSessions() {
 
    this.listData= []
        let dataString = { };
        const sub =   (await this.globalService.request('get', false, 'ionic_getTrainingSessions_V2', dataString)).subscribe(
          async (res) => {
          console.log(res) 
          if( !res){ return;} 
 
          if( res.logout){

            let data:any={ 'logout' : true };

            await this.globalService.logout();
             this.router.navigateByUrl('', { replaceUrl: true });
  
        }

        if( res.isSuspended == true ){

          /* this could be bcause of suspension  */

          let title = res.suspended_title
          let message = res.suspended_message
 
          this.presentSuspendedModal(title,message)

        }
else if( (res.ice_required == true && res.hasIce == false) || (res.parq_required == true && res.hasParq == false) || (res.conduct_required == true && res.hasConduct == false)  ){

          /* IS ICE PARQ of Code of conductREQUIRED */

          let title = res.title
          let message = res.message

          this.presentSuspendedModal(title,message);
          
        }
  else{

          /* IF NOT SUSPENDED */

          if(  res.covid ){
            this.covid = res.covid;
            this.covidSecure = res.covid.secure
          }
          
         this.trainingLength = true
         if(res.training.length == 0){
          this.trainingLength = false
         }

         this.listData['updated'] = true

         this.listData = res.training

         this.trainingCount.next(res.attendingCount)
   
         //this.itemListData.next([])
        this.trainingSessionsData.next(this.listData)

        }


      });
      this.subscriptions.add(sub)
}

public async getTrainingGroupDetails(groupID: any, manage: boolean ) {

  try{

  let dataString = {
    "groupID": groupID,
    "manage": manage.toString()
      };

      const sub =  (await this.globalService.request('get', true, 'ionic_getTrainingGroupDetails_V2', dataString)).subscribe(
     async (res) => {
      if( !res){ return;}
      console.log("GROUP DATA" + res) 
 
      this.groupData.next(res)

    });
   this.subscriptions.add(sub)
  } catch (error) {
    console.error(error);
    return error
  }


 
}
  


async updateGroupDetails(sessionIndex,groupIndex,newData){
 
 let itemListData = this.listData

 itemListData[sessionIndex]['groups'][groupIndex] = newData

 this.trainingSessionsData.next(itemListData)

  }



async presentSuspendedModal(title,message) {
  const modal = await this.modalController.create({
    component: ConfirmModalPage,
    /*breakpoints: [0, 0.5, 1],
        initialBreakpoint: 0.5,*/
    componentProps: {
      'title': title,
      'statement': message,
      'confirm_btn': 'Confirm',
      'not_confirm_btn': false,
      }
  });
  modal.onDidDismiss().then((modelData) => {
  });
  return await modal.present();
}

async presentModal(session,group_id,inOut) {
  const modal = await this.modalController.create({
    component: ConfirmModalPage,
   /*breakpoints: [0, 0.5, 1],
        initialBreakpoint: 0.5,*/
    componentProps: {
      'title': this.covid.title,
      'statement': this.covid.statement,
      'confirm_btn': this.covid.confirm_btn,
      'not_confirm_btn': this.covid.not_confirm_btn,
      }
  });
  modal.onDidDismiss().then((modelData) => {
    if (modelData !== null) {
      this.modelData = modelData.data; 
      this.covidConfirm =  modelData.data.covid;

      if(this.covidConfirm === true){
        this.imin(session,group_id,inOut)
      }
    }
  });
  return await modal.present();
}

async presentOutModal(session,group_id) {
  const modal = await this.modalController.create({
    component: ConfirmModalPage,
    /*breakpoints: [0, 0.5, 1],
        initialBreakpoint: 0.5,*/
    componentProps: {
      'title': 'Leave Group',
      'statement': 'Are you sure?',
      'confirm_btn': 'Confirm',
      'not_confirm_btn': 'Cancel',
      }
  });
  modal.onDidDismiss().then((modelData) => {
    if (modelData !== null) {
      this.modelData = modelData.data;
      this.outConfirm =  modelData.data.covid;

      if(this.outConfirm === true){
        this.imOut(session,group_id)
      }
    }
  });
  return await modal.present();
}



async imin(session_id,group_id,inOut){



  if( this.covidSecure == 1 && inOut != 'out' && !this.covidConfirm){
    this.presentModal(session_id,group_id,inOut);
    return false;
    }

//let group_id =  this.listData[session]['groups'][group].group_id;

let dataString = {'group_id':group_id,
                  'covid':this.covidConfirm
                };
                const sub =     (await this.globalService.request('post', true, 'ionic_postTrainingAttend', dataString)).subscribe(
          (res) => {
          console.log(res)

          if( !res){
            return false;
          }

        this.getTrainingSessions()

      /*    let training_id = res.training.training_id

        console.log(training_id)
           this.covidConfirm = false;

            // update in 
            let sessionKey = this.listData.findIndex(item => item.session_id == training_id);

            console.log(sessionKey)
            let groupKey = this.listData[sessionKey]['groups'].findIndex(item => item.group_id == group_id);
            console.log(groupKey)
            if( res.attend == "training_waiting_list"){
              this.listData[sessionKey]['groups'][groupKey]['im_waiting'] = true
            }
            if( res.attend == "training_attend"){
              this.listData[sessionKey]['groups'][groupKey]['im_in'] = true
            }

            this.listData[sessionKey]['groups'][groupKey]['waiting'] = res.training.waiting
            this.listData[sessionKey]['groups'][groupKey]['attending'] = res.training.attending

            console.log(this.listData[sessionKey]['groups'][groupKey])


            
            let itemListData = this.listData


            this.itemListData.next(itemListData)
*/

           if(res && res.message){
              let message = res.message
              let position = "bottom";
              this.globalService.presentToast(message,3000, position);
            }
              if(res && res.can_review == "1" && Capacitor.getPlatform() != "web"){
                InAppReview.requestReview();
              }
            
              this.trainingCount.next(res.attendingCount) 
      });
      this.subscriptions.add(sub)
}


async imOut(session_id,group_id){

  
  if(!this.outConfirm){
    this.presentOutModal(session_id,group_id);
    return false;
}

let dataString = {'group_id':group_id};
const sub =        (await this.globalService.request('post', true, 'ionic_postTrainingLeave', dataString)).subscribe(
          (res) => {
          console.log(res)

           this.outConfirm = false;

           this.getTrainingSessions()

            // update out 
/*
            let training_id = res.training.training_id
            console.log(training_id)
            console.log(this.listData)

            let sessionKey = this.listData.findIndex(item => item.session_id == training_id);
            console.log(this.listData[sessionKey]['groups'])
            console.log("SESSION KEY " + sessionKey)

            console.log(group_id)

            let groupKey = this.listData[sessionKey]['groups'].findIndex(item => item.group_id == group_id);
            console.log("GROUP KEY " + groupKey)

            this.listData[sessionKey]['groups'][groupKey]['im_in'] = false
            this.listData[sessionKey]['groups'][groupKey]['im_waiting'] = false
            this.listData[sessionKey]['groups'][groupKey]['attending'] = res.training.attending
            this.listData[sessionKey]['groups'][groupKey]['waiting'] = res.training.waiting

            let itemListData = this.listData


            this.itemListData.next(itemListData)
*/

            this.trainingCount.next(res.attendingCount)
      // this.getTrainingGroupDetails(group_id, false )
      // this.getTrainingSessions()

      });
      this.subscriptions.add(sub)
}


ngOnDestroy() {
  // unsubscribe to ensure no memory leaks
  this.subscriptions.unsubscribe();
}

}
